import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';

import {
    ActionsRow,
    Panel,
    ThemeProvider,
    ToggleSwitch,
} from '@pexip/components';
import {PanelHeader} from '@pexip/media-components';

import {useConfig} from '../../contexts/config.context';
import {TestId} from '../../../test/testIds';

export const MeetingSettings: React.FC<{
    onBackClick: () => void;
}> = ({onBackClick}) => {
    const {t} = useTranslation();

    const [isEnableOverlayText, setOverlayTest] = useConfig('nameLabels');
    const toggleEnableOverlayText = useCallback(
        () => setOverlayTest(isEnabled => !isEnabled, true),
        [setOverlayTest],
    );
    return (
        <Panel
            colorScheme="light"
            style={{width: 380}}
            headerPadding="none"
            headerContent={
                <ThemeProvider colorScheme="light">
                    <PanelHeader
                        title={`${t(
                            'settings.meeting-settings',
                            'Meeting settings',
                        )}`}
                        onBackClick={onBackClick}
                        backButtonTestId={TestId.ButtonSettingsBack}
                    />
                </ThemeProvider>
            }
        >
            <ThemeProvider colorScheme="light">
                <ActionsRow
                    title={t('settings.show-name-labels', 'Show name labels')}
                    subtitle={t(
                        'settings.show-name-labels-desc',
                        'Displays participant names in the meeting layout for others to see. You may want to turn this off if taking a screenshot for social media',
                    )}
                >
                    <div style={{paddingLeft: '50px'}}>
                        <ToggleSwitch
                            onChange={toggleEnableOverlayText}
                            labelModifier="hidden"
                            label={t(
                                'settings.show-name-labels',
                                'Show name labels',
                            )}
                            name="name-labels-overlay"
                            checked={isEnableOverlayText}
                        ></ToggleSwitch>
                    </div>
                </ActionsRow>
            </ThemeProvider>
        </Panel>
    );
};
