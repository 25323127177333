import React from 'react';
import {useTranslation} from 'react-i18next';

import {Bar, Button, Panel, ThemeProvider} from '@pexip/components';
import {
    AudioMeter,
    DevicesList,
    PanelHeader,
    SelfViewSettings,
    useAnalyzer,
    usePreviewControllerHandler,
    usePreviewErrorHandling,
} from '@pexip/media-components';
import type {PreviewStreamController} from '@pexip/media';

import {
    MediaContext,
    useDevices,
    usePreviewAudioInput,
    usePreviewAudioOutput,
    usePreviewController,
    usePreviewVideoInput,
    useStreamStatus,
} from '../../contexts/media.context';
import {useAssertedContext} from '../../hooks/useAssertedContext';
import {useConfig} from '../../contexts/config.context';
import {DEFAULT_HEIGHT, DEFAULT_WIDTH} from '../../constants';
import {TestId} from '../../../test/testIds';

export const VideoAndSound: React.FC<{
    onBackClick: () => void;
    close: (e: React.SyntheticEvent<HTMLElement>) => void;
}> = ({close, onBackClick}) => {
    const controller = usePreviewController(true);
    return controller ? (
        <PreviewControllerHandler
            controller={controller}
            close={close}
            onBackClick={onBackClick}
        />
    ) : null;
};

const PreviewControllerHandler: React.FC<{
    controller: PreviewStreamController;
    onBackClick: () => void;
    close: (e: React.SyntheticEvent<HTMLElement>) => void;
}> = ({close, onBackClick, controller}) => {
    const {t} = useTranslation();

    const m = useAssertedContext(MediaContext);

    const devices = useDevices(() => m.devices);
    const streamStatus = useStreamStatus(() => m.media.status);

    const {
        videoInputError,
        setVideoInputError,
        audioInputError,
        setAudioInputError,
    } = usePreviewErrorHandling(controller, streamStatus, {
        audio: Boolean(controller.media.constraints?.audio),
        video: Boolean(controller.media.constraints?.video),
    });

    const audioInput = usePreviewAudioInput({
        error: audioInputError,
        controller,
        setError: setAudioInputError,
    });

    const videoInput = usePreviewVideoInput({
        error: videoInputError,
        controller,
        setError: setVideoInputError,
    });

    const audioOutput = usePreviewAudioOutput(
        devices,
        useConfig('audioOutput')[0],
    );

    const previewController = usePreviewControllerHandler({
        controller,
        close,
        applyChanges: [
            videoInput.applyChanges,
            audioInput.applyChanges,
            audioOutput.applyChanges,
        ],
    });

    const requestedAudio = Boolean(
        previewController.previewMedia.constraints?.audio,
    );

    const requestedVideo = Boolean(
        previewController.previewMedia.constraints?.video,
    );

    const analyzer = useAnalyzer(controller.media);
    const isSaving =
        previewController.isSaving || previewController.updatingPreview;
    const allowToSave =
        !previewController.isSaving &&
        /*
         * Respective device selection is disabled when requestedVideo or requestedAudio
         * is false, this should not hinder the user from saving other changes.
         */
        (!videoInput.error.title || !requestedVideo) &&
        (!audioInput.error.title || !requestedAudio);

    return (
        <Panel
            style={{width: 380}}
            colorScheme="light"
            hasFooterShadow
            headerPadding="none"
            headerContent={
                <ThemeProvider colorScheme="light">
                    <PanelHeader
                        title={`${t(
                            'settings.video-and-sound',
                            'Video and Sound',
                        )}`}
                        onBackClick={onBackClick}
                        backButtonTestId={TestId.ButtonSettingsBack}
                    />
                </ThemeProvider>
            }
            footerContent={
                <ThemeProvider colorScheme="light">
                    <Bar>
                        <Button
                            variant="secondary"
                            modifier="fullWidth"
                            onClick={close}
                            isDisabled={isSaving}
                        >
                            Close
                        </Button>
                        <Button
                            modifier="fullWidth"
                            className="ml-2"
                            isLoading={isSaving}
                            isDisabled={!allowToSave}
                            onClick={(e: React.SyntheticEvent<HTMLElement>) => {
                                const handleSave = async () => {
                                    // FIXME: workaround to keep the same ratio as a main  stream
                                    await controller?.media?.applyConstraints({
                                        video: {
                                            width: DEFAULT_WIDTH,
                                            height: DEFAULT_HEIGHT,
                                        },
                                    });
                                    await previewController.handleSave(e);
                                };

                                void handleSave();
                            }}
                        >
                            Save
                        </Button>
                    </Bar>
                </ThemeProvider>
            }
        >
            <ThemeProvider colorScheme="light">
                <SelfViewSettings srcObject={controller.media?.stream} />
                {!!previewController.previewMedia?.audioInput && !!analyzer && (
                    <div className="mb-2">
                        <AudioMeter analyzer={analyzer} width={374} />
                    </div>
                )}
                <DevicesList
                    devices={devices}
                    requestedAudio={requestedAudio}
                    requestedVideo={requestedVideo}
                    videoInputError={videoInput.error}
                    audioInputError={audioInput.error}
                    videoInput={videoInput.preview}
                    audioInput={audioInput.preview}
                    audioOutput={audioOutput.preview}
                    onAudioInputChange={audioInput.setPreview}
                    onAudioOutputChange={audioOutput.setPreview}
                    onVideoInputChange={videoInput.setPreview}
                    isLoading={previewController.isSaving}
                />
            </ThemeProvider>
        </Panel>
    );
};
